<template>
  <div style="padding: 20px">
    <div class="stepbox">
      <div>
        <p>1.开票订单选择</p>
        <div class="threeth"></div>
      </div>
      <div>
        <p>2.确定发票信息和地址</p>
        <div class="threeth"></div>
      </div>
    </div>
    <div class="requestInvoice">
      <div>
        <div class="tit">
          <span>开票明细</span>
          <a @click="showPartFn" href="javascript:;">修改发票信息</a>
        </div>
        <div class="cont">
          <div class="left">
            <p>发票抬头：{{ InvoiceData.receipt_title }}</p>
            <p>
              发票类型：{{ InvoiceData.receipt_type == "ELECTRO" ? "电子普通发票"
                : InvoiceData.receipt_type === 'VATOSPECIAL' ? "增值税专用发票" : "增值税普通发票" }}
            </p>
            <p>
              发票金额：￥{{ price }}
            </p>
            <!-- <p>发票内容：{{InvoiceData.receipt_content}}</p> -->
            <p>发票税号：{{ InvoiceData.tax_no }}</p>
          </div>
          <div class="right">
            <!--            <p>开户银行：{{ InvoiceData.bank_name }}</p>-->
            <!--            <p>银行账户：{{ InvoiceData.bank_account }}</p>-->
            <!--            <p>注册地址：{{ InvoiceData.reg_addr }}</p>-->
            <!--            <p>注册电话：{{ InvoiceData.reg_tel }}</p>-->
            <p>申请时间: {{ formatCreatTime(currentTime) }}</p>
            <p>收货人: {{ address.receive_name }}</p>
            <p>联系电话: {{ address.receive_phone }}</p>
            <p>收货地址: {{ address.province }} {{ address.city }} {{ address.county }} {{ address.town }} {{
              address.receive_addr }}</p>
          </div>
          <div class="btn">
            <el-button @click="submits()" type="primary">确认并提交</el-button>
          </div>
        </div>
      </div>
      <div class="tables">
        <div class="table_tit">订单信息</div>
        <en-table-layout toolbar pagination :tableData="tableData.data" ref="multipleTable">
          <template slot="table-columns">
            <el-table-column label="订单号" prop="sn"></el-table-column>
            <el-table-column label="订单完成时间" props="create_time" :formatter="formatCreatTime"></el-table-column>
            <el-table-column label="供应商（发票开具方）" prop="seller_name"></el-table-column>
            <el-table-column label="商品名称 x 购买数量 / 商品单价" width="400px">
              <template slot-scope="scope">
                <div v-for="(item, index) in scope.row.items_json" :key="index">
                  {{ item.name }} x {{ item.num }} 单价: {{ item.supplier_purchase_price }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="开票金额" prop="order_price">
              <template slot-scope="scope">
                ¥ {{ scope.row.supplier_order_price - scope.row.supplier_refund_price }}
              </template>
            </el-table-column>
          </template>
          <el-pagination background v-if="tableData" slot="pagination" @size-change="handlePageSizeChange"
            @current-change="handlePageCurrentChange" :current-page="tableData.page_no" :page-sizes="MixinPageSizes"
            :page-size="tableData.page_size" :layout="MixinTableLayout" :total="tableData.data_total"></el-pagination>
        </en-table-layout>
      </div>
    </div>
  </div>
</template>
<script>
import * as API_Invoice from "@/api/invoice";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
export default {
  components: { EnTableLayout },
  data () {
    return {
      params: {
        page_no: 1,
        page_size: 20,
        startTime: "",
        endTime: "",
        step: 2,
      },
      InvoiceData: {},
      ids: "",
      price: 0,
      tableData: {},
      address: {},
      currentTime: ''
    };
  },
  mounted () {
    this.currentTime = Date.parse(new Date()) / 1000
    this.ids = this.$route.query.id;
    this.price = this.$route.query.price;
    this.getInvoiceTitleDetail();
    this.getWaitReceiptOrderList();
  },
  methods: {
    formatCreatTime (row, column, cellValue) {
      if (typeof row === 'object') {
        return this.$options.filters.unixToDate(row.create_time);
      } else {
        return this.$options.filters.unixToDate(row);
      }
    },
    showPartFn () {
      this.$router.push({ path: "/supplier/Invoicemangerdetail" });
    },
    getInvoiceTitleDetail () {
      // 搜索发票抬头信息
      API_Invoice.getDetail().then((res) => {
        this.InvoiceData = res.shop_receipt;
        this.address = res.address
      });
    },
    getWaitReceiptOrderList () {
      API_Invoice.getInvoiceOrderList(this.params).then((res) => {
        this.tableData = res;
        let dataList = this.tableData.data;
        dataList.map((item, index) => {
          dataList[index].items_json = JSON.parse(dataList[index].items_json);
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.getWaitReceiptOrderList();
    },
    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getWaitReceiptOrderList();
    },
    submits () {
      let data = null;
      data = { step: 2 };
      if (this.$route.query.is_all == "0") {
        data.is_all = 0;
        data.order_ids = this.ids.split(',');
      } else {
        data.is_all = 1;
        data.order_ids = ["0"];
      }
      API_Invoice.addHistory(data).then((res) => {
        this.$message.success("提交成功");
        this.$router.push({ path: "/supplier/terraceInvoice" });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.stepbox {
  background: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 20px;
  display: -webkit-flex;
  justify-content: center;
  padding: 20px 0;

  >div {
    display: inline-block;
    width: 380px;
    height: 48px;
    text-align: center;

    p {
      width: 340px;
      height: 48px;
      background: #1a43a9;
      line-height: 48px;
      margin: 0;
      text-align: center;
      color: #ffffff;
      float: left;
    }

    div {
      float: left;
      width: 0;
      height: 0;
      border-left: 14px solid #1a43a9;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }

    .threeths {
      border-left: 14px solid #ffffff;
      border-top: 24px solid #1a43a9;
      border-bottom: 24px solid #1a43a9;
    }
  }

  .activediv {
    p {
      background: #c0c4cc;
    }

    .threeths {
      border-top: 24px solid #c0c4cc;
      border-bottom: 24px solid #c0c4cc;
      border-left: 14px solid #ffffff;
    }

    div {
      float: left;
      border-left: 14px solid #c0c4cc;
      border-top: 24px solid #ffffff;
      border-bottom: 24px solid #ffffff;
    }
  }
}

.requestInvoice {
  &>div {
    width: 100%;
    padding: 20px;
    background: #fff;
    border-radius: 4px;
  }

  .tit {
    span {
      font-size: 18px;
      color: #666;
    }

    a {
      color: #0099ff;
    }
  }

  .cont {
    margin-top: 20px;
    display: -webkit-flex;

    &>div {
      width: 50%;
      color: #666;
    }

    .left>div,
    .right>div {
      margin-bottom: 20px;
    }

    .btn {
      text-align: center;
      margin-top: 20px;
    }
  }

  .tables {
    margin-top: 20px;
    padding: 20px;

    .table_tit {
      font-size: 18px;
      color: #666;
      margin-bottom: 20px;
    }

    .pages {
      margin-top: 20px;
    }

    .btn {
      text-align: center;
      margin-top: 20px;
    }
  }
}
</style>
